import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { clas } from '~/utils/clas';
import TemplateTheme from '../_private/TemplateTheme';
const classes = {
    Root: styled.div `
    width: 100%;
    height: 100%;
  `,
    button: {
        Root: styled.div ``,
        default: {
            Default: styled.div ``,
            Active: styled.div ``,
        },
        long: {
            Default: styled.div ``,
            Active: styled.div ``,
        },
        short: {
            Default: styled.div ``,
            Active: styled.div ``,
        },
    },
    Inner: styled.div ``,
};
const defaultCsses = css `
  ${TemplateTheme.classes.Dark} {
  }

  ${classes.button.Root} {
    ${flex.h.allCenter};
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  ${classes.button.default.Default} {
    background-color: rgb(179, 181, 189);
    border: 1px solid rgb(153, 153, 153);
    color: rgb(119, 119, 119);

    &:hover {
      color: rgb(34, 34, 34);
      background-color: rgb(213 216 226);
      border: 1px solid rgb(76, 86, 95);
    }
  }

  ${classes.button.default.Active} {
    color: rgb(34, 34, 34);
    background-color: rgb(224, 227, 235);
    border: 1px solid rgb(92, 110, 126);
  }

  ${classes.button.long.Default} {
    color: rgb(119, 119, 119);
    background-color: rgb(85, 0, 0);

    &:hover {
      background-color: rgb(187, 0, 0);
    }
  }

  ${classes.button.long.Active} {
    color: white;
    background-color: rgb(187, 0, 0);
    border: 1px solid rgb(255, 187, 187);

    &:hover {
      background-color: rgb(187, 0, 0);
    }
  }

  ${classes.button.short.Default} {
    color: rgb(119, 119, 119);
    background-color: rgb(0, 85, 0);

    &:hover {
      background-color: rgb(0, 187, 0);
    }
  }

  ${classes.button.short.Active} {
    color: white;
    background-color: rgb(0, 187, 0);
    border: 1px solid rgb(187, 255, 187);

    &:hover {
      background-color: rgb(0, 187, 0);
    }
  }
`;
const FuiButton = memo(function FuiButton(props) {
    const defaultClassName = !props.variant && classes.button.default.Default;
    const defaultActiveClassName = !props.variant && props.active && classes.button.default.Active;
    const longClassName = props.variant === 'long' && classes.button.long.Default;
    const longActiveClassName = props.variant === 'long' && props.active && classes.button.long.Active;
    const shortClassName = props.variant === 'short' && classes.button.short.Default;
    const shortActiveClassName = props.variant === 'short' && props.active && classes.button.short.Active;
    return (<classes.Root css={defaultCsses} className={props.className}>
      <classes.button.Root data-test-variant-default={defaultClassName} data-test-variant-default-active={defaultActiveClassName} data-test-variant-long={longClassName} data-test-variant-long-active={longActiveClassName} data-test-variant-short={shortClassName} data-test-variant-short-active={shortActiveClassName} onClick={event => {
            props.onClick?.();
        }} className={clas([
            defaultClassName,
            defaultActiveClassName,
            longClassName,
            longActiveClassName,
            shortClassName,
            shortActiveClassName,
        ])}>
        <classes.Inner>{props.children}</classes.Inner>
      </classes.button.Root>
    </classes.Root>);
});
export default {
    Display: FuiButton,
    classes,
};
